import React from 'react';

export default {
  register: '登記',
  registerNow: '立即登記',
  bookAppointment: <>即刻預約</>,
  bookAppt: '即刻預約',
  header: {
    routes: [
      {
        path: '/floorplans',
        text: '戶型圖',
      },
      {
        path: '/interiors',
        text: '室內設計',
      },
      {
        path: '/amenities',
        text: '生活設施',
      },
      {
        path: '/neighbourhood',
        text: '社區',
      },
      {
        path: '/gallery',
        text: '圖庫',
      },
      {
        path: '/developer',
        text: '發展商',
      },
    ],
  },
  footer: {
    column1: {
      title: '聯繫我們',
      buttonLabel: '立即登記',
    },
    column2: {
      title: '展銷中心',
      text: (
        <>
          每天 12 - 5 PM 開放 <br />
          （週五休息）
          <br />
          <br />
          卑詩省本拿比市
          <br />
          Imperial 街 4700 號
        </>
      ),
    },
    column3: {
      title: '樓盤地點',
    },
    marketing: 'marketing in association with',
    disclaimer:
      '*Prices and incentives are subject to change without notice. The 3% savings will be calculated on the purchase price net of GST and shown as credit on statement of adjustments upon completion. Please contact a Sales Representative for more information. The information and pricing contained in this promotional material is for illustration purposes only and is subject to change without notice. Maps and renderings are an artistic representation only. The developer reserves to the right to make modifications or substitutions should they be necessary. Offers can only be made by way of a disclosure statement. LM Riviera Homes Limited Partnership develops the quality residences at Riviera. E. & O.E',
    corporate: {
      title: '信心．質素．超值',
      text: 'Ledingham McAllister 致力於在黃金地段打造優質住宅，持續為客戶開創長期價值。我們秉持最高標準，憑藉我們過去建立的卓越口碑，我們定能開創精彩未來。',
      privacy: '隱私政策',
    },
  },
  home: {
    header: (
      <>
        以中世紀
        <br className="mobile" />
        典雅風格 <br className="tablet" />
        開創豪華
        <br className="mobile" />
        現代生活
      </>
    ),
    hero: {
      title:
        '傲居鐵道鎮黃金地段，Riviera 以世界級的工藝與中世紀的典雅美學，體現最卓越的都市生活。',
      text: '樓高 38 層，以高級建材與非凡設施，匠心打造 1 房加書房至 3 房的系列寬敞精品住宅，呈現無比豐富的生活體驗。這，就是與公園為鄰的 Riviera 豪華社區。',
      button: '觀看戶型圖',
    },
    content1: {
      title: (
        <>
          典雅風格
          <br />
          完美呈現
        </>
      ),
      text: '從踏入家門的那刻起，您將彷彿置身一個截然不同的世界。這個舒適的生活環境空間寬敞，從精心規劃的空間佈局，到各式各樣的裝修細節，能為您與家人帶來最完美的生活，更能讓賓客們流連忘返。',
      button: '探索室內設計',
    },
    content2: {
      title: (
        <>
          生活時尚
          <br />
          齊聚一堂
        </>
      ),
      text: 'Riviera 將生活空間，由家中延伸至整個社區之內。搭著電梯，就能輕鬆抵達專屬樓層，享受宛如酒店渡假村內的豪華設施。不論是追求身心靈的健康平衡，或是體驗各類精彩娛樂，這裡皆是一應俱全！',
      button: 'Explore Amenities',
    },
    content3: {
      title: (
        <>
          動靜皆宜
          <br />
          多彩多姿
        </>
      ),
      text: '與 Lobley 公園毗鄰的 Riviera，傲居靜謐環境與都會生活的匯集點，距離全國知名的購物、餐飲及休閒娛樂好去處，僅有徒步之遙。',
      button: '探索社區',
    },
  },
  developer: {
    hero: {
      title: '信心．質素．超值',
      body: '在Ledingham McAllister，我們致力於精心策劃發展，挑選優越的地理位置，創建卓越新住宅社區。一個多世紀以來，我們一直遵循著嚴格的設計、環保領導力、工程和施工標準，以確保住宅具有持久的品質和價值。在建造了超過 20,000 所住宅建築後，我們可以自豪地說，此理念創造了我們傲人的歷史，也將帶領我們邁向成功的未來。',
    },
    companies: {
      ibi: {
        description:
          'IBI GROUP現已成為ARCADIS一份子。ARCADIS GROUP是一家科技驅動的設計公司，近50年來一直為客戶提供建築設計 、工程、規劃、系統和技術服務。我們在全球60多個辦事處擁有2700多名專業人員，通過將我們的智慧實踐中的既定能力引入我們的覈心建築和基礎設施業務，我們創造了快速回應的彈性空間和智慧城市環境。從高樓到醫院，從交通系統到學校，我們塑造著人們在未來都市中生活，工作，移動，學習和療癒的方式。',
      },
      pwl: {
        description:
          'PWL Partnership的精英團隊包括庭院設計師丶城市設計師和規劃師，過去 40 年在加拿大，美國和亞洲已經建立了卓越的聲譽。 他們的辦公室有 5 位合夥人，4 位準合夥人，以及一個由庭院建築師和設計師組成的 30 人團隊。 PWL 總部位於溫哥華，致力與客戶群，項目團隊和當地持份者建立有意義且持久的關係。該公司擁有豐富設計經驗，範疇包括海濱、綠色通道、公交走廊、城市廣場、街道、鄰里公園，以及把停車位改造成路邊公園等。',
      },
      themill: {
        description:
          'Mill Design Group Inc. 由室內設計師 Janine Wilson 於 2011 年創立。Mill Design Group Inc.位於美麗的北溫哥華，專門從事多戶型住宅設計。憑藉在室內設計行業超過 18 年的經驗，Janine 和她出色的團隊與發展商合作，創造出既能反映社區最佳環境，又能體現未來買家審美觀和價值觀的室內裝飾和家居裝飾。他們的設計理念是，室內裝修應該是永恒的和易於管理，而不會犧牲風格和舒適性。',
      },
    },
    projects: [
      {
        image: require('src/assets/images/developer/projects/01_escala-burnaby.jpg'),
        text: 'Escala, 本拿比',
        small: true,
      },
      {
        image: require('src/assets/images/developer/projects/02_aviara-burnaby.jpg'),
        text: 'Aviara, 本拿比',
        small: true,
      },
      {
        image: require('src/assets/images/developer/projects/03_reflections-burnaby.jpg'),
        text: 'Reflections, 本拿比',
        small: true,
      },
      {
        image: require('src/assets/images/developer/projects/04_waterscapes-kelowna.jpg'),
        text: 'Waterscapes, 基隆拿',
      },
      {
        image: require('src/assets/images/developer/projects/05_highpoint-coquitlam.jpg'),
        text: 'Highpoint, 高貴林',
      },
      {
        image: require('src/assets/images/developer/projects/06_icon-burnaby.jpg'),
        text: 'Icon, 本拿比',
      },
      {
        image: require('src/assets/images/developer/projects/07_sydney-coquitlam.jpg'),
        text: 'Sydney, 高貴林',
      },
      {
        image: require('src/assets/images/developer/projects/08_azure-burnaby.jpg'),
        text: 'Azure, 本拿比',
      },
      {
        image: require('src/assets/images/developer/projects/09_northgate_village-burnaby.jpg'),
        text: 'Northgate Village, 本拿比',
      },
      {
        image: require('src/assets/images/developer/projects/10_perspectives-burnaby.jpg'),
        text: 'Perspectives, 本拿比',
      },
      {
        image: require('src/assets/images/developer/projects/11_saltaire-white_rock.jpg'),
        text: 'Saltaire, White Rock',
      },
      {
        image: require('src/assets/images/developer/projects/12_dominion-new_westminster.jpg'),
        text: 'Dominion, New Westminster',
      },
    ],
  },
  form: {
    title: '立即登記',
    firstname: '名字*',
    lastname: '姓氏*',
    email: '電郵*',
    phone: '電話*',
    zip: '郵編*',
    select: '請選擇',
    howHeard: '您如何得知關於我們的資訊？*',
    howHeardOptions: [
      {
        label: 'Chinese Online Ads',
        value: 'Chinese Online Ads',
      },
      {
        label: 'Chinese Print Ads',
        value: 'Chinese Print Ads',
      },
      {
        label: 'Community Paper',
        value: 'Community Paper',
      },
      {
        label: 'VanChosun',
        value: 'VanChosun',
      },
      {
        label: 'Korean Real Estate Weekly',
        value: 'Korean Real Estate Weekly',
      },
      {
        label: 'Facebook / Instagram',
        value: 'Facebook / Instagram',
      },
      {
        label: 'Google Search',
        value: 'Google Search',
      },
      {
        label: 'Ledmac Email',
        value: 'Ledmac Email',
      },
      {
        label: 'Ledmac Website',
        value: 'Ledmac Website',
      },
      {
        label: 'Mail Piece',
        value: 'Mail Piece',
      },
      {
        label: 'MLS.ca / Realtor.ca',
        value: 'MLS.ca / Realtor.ca',
      },
      {
        label: 'Online Ads',
        value: 'Online Ads',
      },
      {
        label: 'Outdoor Advertising',
        value: 'Outdoor Advertising',
      },
      {
        label: 'Realtor',
        value: 'Realtor',
      },
      {
        label: 'Signage',
        value: 'Signage',
      },
      {
        label: 'Skytrain',
        value: 'Skytrain',
      },
      {
        label: 'Social Media',
        value: 'Social Media',
      },
      {
        label: 'The Province',
        value: 'The Province',
      },
      {
        label: 'Vancouver Sun',
        value: 'Vancouver Sun',
      },
      {
        label: 'WeChat',
        value: 'WeChat',
      },
      {
        label: 'Word of Mouth',
        value: 'Word of Mouth',
      },
    ],
    whatInquire: '您感興趣的房型為何？',
    whatInquireOptions: [
      {
        label: '一房',
        value: 'One bedroom',
      },
      {
        label: '兩房',
        value: 'Two bedroom',
      },
      {
        label: '三房',
        value: 'Three bedroom',
      },
      {
        label: '頂樓',
        value: 'Penthouses',
      },
    ],
    realtor: '您是否為地產經紀？*',
    workingWithRealtor: '您有合作的地產經紀嗎？',
    yes: '是',
    no: '否',
    ifYesRealtor: `如果您是地產經紀，或有合作的地產經紀，請輸入公司名稱或地產經紀的姓名:`,
    disclaimer: '我願意收到有關銷售與行銷的訊息',
    submit: '提交',
    required: '* 必填欄位',
  },
  gallery: {
    hero: {
      title: '極致空間 優質環境',
      smallStrapline:
        '讓生活空間最大化的明亮開放式戶型規劃，搭配全尺寸的豪華家電組',
    },
    interiors: {
      title: '室內設計',
      images: [
        // {
        //   src: require('src/assets/images/gallery/interiors/lightbox/living-space_light.jpg'),
        //   thumb: require('src/assets/images/gallery/interiors/thumbs/living-space_light.jpg'),
        //   alt: 'Kitchen | Opal Scheme',
        // },
        // {
        //   src: require('src/assets/images/gallery/interiors/lightbox/living-space_dark.jpg'),
        //   thumb: require('src/assets/images/gallery/interiors/thumbs/living-space_dark.jpg'),
        //   alt: 'Kitchen | Onyx Scheme',
        // },
        // {
        //   src: require('src/assets/images/gallery/interiors/lightbox/ensuite_light.jpg'),
        //   thumb: require('src/assets/images/gallery/interiors/thumbs/ensuite_light.jpg'),
        //   alt: 'Ensuite | Opal Scheme',
        // },
        // {
        //   src: require('src/assets/images/gallery/interiors/lightbox/ensuite_dark.jpg'),
        //   thumb: require('src/assets/images/gallery/interiors/thumbs/ensuite_dark.jpg'),
        //   alt: 'Ensuite | Onyx Scheme',
        // },
        {
          src: require('src/assets/images/gallery/interiors/lightbox/display-suite-12.jpg'),
          thumb: require('src/assets/images/gallery/interiors/thumbs/display-suite-12.jpg'),
          alt: '1 Bed | Kitchen',
        },
        {
          src: require('src/assets/images/gallery/interiors/lightbox/display-suite-05.jpg'),
          thumb: require('src/assets/images/gallery/interiors/thumbs/display-suite-05.jpg'),
          alt: '1 Bed | Integrated Refrigerator',
        },
        {
          src: require('src/assets/images/gallery/interiors/lightbox/display-suite-11.jpg'),
          thumb: require('src/assets/images/gallery/interiors/thumbs/display-suite-11.jpg'),
          alt: '1 Bed | Kitchen & Living',
        },
        {
          src: require('src/assets/images/gallery/interiors/lightbox/display-suite-13.jpg'),
          thumb: require('src/assets/images/gallery/interiors/thumbs/display-suite-13.jpg'),
          alt: '1 Bed | Living',
        },
        {
          src: require('src/assets/images/gallery/interiors/lightbox/display-suite-02.png'),
          thumb: require('src/assets/images/gallery/interiors/thumbs/display-suite-02.jpg'),
          alt: '1 Bed | Den',
        },
        {
          src: require('src/assets/images/gallery/interiors/lightbox/display-suite-01.jpg'),
          thumb: require('src/assets/images/gallery/interiors/thumbs/display-suite-01.jpg'),
          alt: '1 Bed | Main Bathroom',
        },
        {
          src: require('src/assets/images/gallery/interiors/lightbox/display-suite-04.jpg'),
          thumb: require('src/assets/images/gallery/interiors/thumbs/display-suite-04.jpg'),
          alt: '1 Bed | Main Bedroom',
        },

        {
          src: require('src/assets/images/gallery/interiors/lightbox/display-suite-16.jpg'),
          thumb: require('src/assets/images/gallery/interiors/thumbs/display-suite-16.jpg'),
          alt: '2 Bed | Kitchen',
        },
        {
          src: require('src/assets/images/gallery/interiors/lightbox/display-suite-18.png'),
          thumb: require('src/assets/images/gallery/interiors/thumbs/display-suite-18.jpg'),
          alt: '2 Bed | Kitchen',
        },
        {
          src: require('src/assets/images/gallery/interiors/lightbox/display-suite-15.jpg'),
          thumb: require('src/assets/images/gallery/interiors/thumbs/display-suite-15.jpg'),
          alt: '2 Bed | Dining & Kitchen',
        },
        {
          src: require('src/assets/images/gallery/interiors/lightbox/display-suite-17.jpg'),
          thumb: require('src/assets/images/gallery/interiors/thumbs/display-suite-17.jpg'),
          alt: '2 Bed | Kitchen & Dining',
        },
        {
          src: require('src/assets/images/gallery/interiors/lightbox/display-suite-14.jpg'),
          thumb: require('src/assets/images/gallery/interiors/thumbs/display-suite-14.jpg'),
          alt: '2 Bed | Kitchen & Living',
        },
        {
          src: require('src/assets/images/gallery/interiors/lightbox/display-suite-19.jpg'),
          thumb: require('src/assets/images/gallery/interiors/thumbs/display-suite-19.jpg'),
          alt: '2 Bed | Living',
        },
        {
          src: require('src/assets/images/gallery/interiors/lightbox/display-suite-03.png'),
          thumb: require('src/assets/images/gallery/interiors/thumbs/display-suite-03.jpg'),
          alt: '2 Bed | Main Bathroom',
        },
        {
          src: require('src/assets/images/gallery/interiors/lightbox/display-suite-08.jpg'),
          thumb: require('src/assets/images/gallery/interiors/thumbs/display-suite-08.jpg'),
          alt: '2 Bed | Main Bedroom',
        },
        {
          src: require('src/assets/images/gallery/interiors/lightbox/display-suite-06.png'),
          thumb: require('src/assets/images/gallery/interiors/thumbs/display-suite-06.jpg'),
          alt: '2 Bed | Main Bedroom Closet',
        },

        {
          src: require('src/assets/images/gallery/interiors/lightbox/display-suite-07.png'),
          thumb: require('src/assets/images/gallery/interiors/thumbs/display-suite-07.jpg'),
          alt: '2 Bed | Ensuite',
        },
        {
          src: require('src/assets/images/gallery/interiors/lightbox/display-suite-09.jpg'),
          thumb: require('src/assets/images/gallery/interiors/thumbs/display-suite-09.jpg'),
          alt: '2 Bed | 2nd Bedroom',
        },
        {
          src: require('src/assets/images/gallery/interiors/lightbox/display-suite-10.jpg'),
          thumb: require('src/assets/images/gallery/interiors/thumbs/display-suite-10.jpg'),
          alt: '2 Bed | Balcony',
        },
      ],
    },
    neighbourhood: {
      title: '社區',
      images: [
        {
          src: require('src/assets/images/gallery/neighbourhood/gallery_01.jpg'),
          thumb: require('src/assets/images/gallery/neighbourhood/gallery_01_thumb.jpg'),
          alt: '鐵道鎮',
        },
        {
          src: require('src/assets/images/gallery/neighbourhood/gallery_02.jpg'),
          thumb: require('src/assets/images/gallery/neighbourhood/gallery_02_thumb.jpg'),
          alt: 'Metropolis 購物中心',
        },
        {
          src: require('src/assets/images/gallery/neighbourhood/gallery_03.jpg'),
          thumb: require('src/assets/images/gallery/neighbourhood/gallery_03_thumb.jpg'),
          alt: '捷運站',
        },
        {
          src: require('src/assets/images/gallery/neighbourhood/gallery_04.jpg'),
          thumb: require('src/assets/images/gallery/neighbourhood/gallery_04_thumb.jpg'),
          alt: '中央公園',
        },
        {
          src: require('src/assets/images/gallery/neighbourhood/gallery_05.jpg'),
          thumb: require('src/assets/images/gallery/neighbourhood/gallery_05_thumb.jpg'),
          alt: '鹿湖',
        },
        {
          src: require('src/assets/images/gallery/neighbourhood/gallery_06.jpg'),
          thumb: require('src/assets/images/gallery/neighbourhood/gallery_06_thumb.jpg'),
          alt: '本地超市',
        },
        {
          src: require('src/assets/images/gallery/neighbourhood/gallery_07.jpg'),
          thumb: require('src/assets/images/gallery/neighbourhood/gallery_07_thumb.jpg'),
          alt: '高級餐館',
        },
        {
          src: require('src/assets/images/gallery/neighbourhood/gallery_08.jpg'),
          thumb: require('src/assets/images/gallery/neighbourhood/gallery_08_thumb.jpg'),
          alt: '精品購物',
        },
        {
          src: require('src/assets/images/gallery/neighbourhood/gallery_09.jpg'),
          thumb: require('src/assets/images/gallery/neighbourhood/gallery_09_thumb.jpg'),
          alt: '西門菲莎大學',
        },
      ],
    },
    building: {
      title: '社區建築',
      images: [
        {
          src: require('src/assets/images/gallery/building/lightbox/exterior.jpg'),
          thumb: require('src/assets/images/gallery/building/thumbs/exterior.jpg'),
          alt: '車道入口',
        },
        {
          src: require('src/assets/images/gallery/building/lightbox/entrance.jpg'),
          thumb: require('src/assets/images/gallery/building/thumbs/entrance.jpg'),
          alt: '社區入口',
        },
        {
          src: require('src/assets/images/gallery/building/lightbox/terrace.jpg'),
          thumb: require('src/assets/images/gallery/building/thumbs/terrace.jpg'),
          alt: '戶外露台',
        },
        {
          src: require('src/assets/images/gallery/building/lightbox/lobby-concierge.jpg'),
          thumb: require('src/assets/images/gallery/building/thumbs/lobby-concierge.jpg'),
          alt: '禮賓服務',
        },
        {
          src: require('src/assets/images/gallery/building/lightbox/lobby-fireplace.jpg'),
          thumb: require('src/assets/images/gallery/building/thumbs/lobby-fireplace.jpg'),
          alt: '迎賓大廳',
        },
        {
          src: require('src/assets/images/gallery/building/lightbox/amenity_ping-pong.jpg'),
          thumb: require('src/assets/images/gallery/building/thumbs/amenity_ping-pong.jpg'),
          alt: '社交休息區',
        },
        {
          src: require('src/assets/images/gallery/building/lightbox/amenity_kitchen.jpg'),
          thumb: require('src/assets/images/gallery/building/thumbs/amenity_kitchen.jpg'),
          alt: '主廚廚房與用餐區',
        },
        {
          src: require('src/assets/images/gallery/building/lightbox/rooftop.jpg'),
          thumb: require('src/assets/images/gallery/building/thumbs/rooftop.jpg'),
          alt: '空中花園',
        },
      ],
    },
  },
  interiors: {
    hero: {
      title: '典雅風格 完美呈現',
      fullStrapline:
        '從踏入家門的那刻起，您將彷彿置身一個截然不同的世界。這個舒適的空間，以特大窗戶將自然光與新鮮空氣迎入室內，讓家中光線充足、空氣清新。不僅如此，還有一個一年四季皆可享受戶外生活的大型陽台。如此卓越的居住環境，不僅能為您與家人帶來最完美的生活，更能讓賓客們流連忘返！ ',
    },
    opal: '澳寶白',
    onyx: '瑪瑙黑',
    opalOnyx: {
      content2: {
        title: '品味空間 任君挑選',
        body: '這個舒適的生活空間，從佈局到各式的裝修細節都獨具匠心。並有 The Mill Design Group 大師調配的兩款主題色調任君挑遠。兩款色調分別為溫暖且活力十足的澳寶白，以及時尚且高貴典雅的瑪瑙黑，讓您能依照喜好，為生活空間增添個人色彩！',
      },
    },
    features: {
      title: '智能設計',
      column1: [
        '寬大且由豪華石材打造的側落式中島，讓您擁有足夠空間準備各類美食',
        '包含食物儲藏間、並且與時尚石英檯面及無縫防濺水牆相匹配的全高度大型櫥櫃',
        '主廚等級的全尺寸家電組，包含五爐灶的 Bosch 天然氣爐台，以及內嵌式的  Fisher & Paykel 法式雙門冰箱',
      ],
      column2: [
        '全尺寸的 Whirlpool 節能前置式洗衣與乾衣機',
        '設計靈感源自於私人水療中心的衛浴套間，包含附設下置式柔光照明的精美懸掛式梳洗台，以及定製的梳妝鏡櫃',
        '無框玻璃淋浴隔間或寬敞的浸泡式浴缸，與衛浴套間內呈現的放鬆氛圍相得益彰',
      ],
      download: '下載特色介紹',
    },
  },
  amenities: {
    hero: {
      title: '宏偉氣派 歡迎回家',
      fullStrapline:
        '由獲獎無數的 IBI Architects 精心設計，見到 Riviera 的第一眼，就能令人留下深刻印象。在宏偉的社區入口，首先映入眼簾的是美麗水畔造景，搭配散發著奢華氣息的壯麗金色格子狀藝術景觀，不僅如此，宛若五星酒店的大型車道，還能讓您在此氣派地迎接或歡送遠道而來的三五好友。當然，您也能在此便利地收取剛剛送達的快遞或生活用品。在踏入宏偉的迎賓大廳後，特大典雅吊燈撒下備感溫馨的光線，加上迎賓處服務專員的親切款待，肯定能讓您與親友感受到與中世紀貴族同等的尊爵感受！',
    },
    content1: {
      title: (
        <>
          生活時尚
          <br />
          齊聚一堂
        </>
      ),
      body: '搭著電梯，就能輕鬆抵達專屬樓層，享受宛如酒店渡假村內的豪華設施，讓生活變得更加多采多姿！在這一整層樓的空間當中，除了設備齊全的健身中心，讓您隨時都能鍛鍊體魄之外，還有多個能令來訪親友感到賓至如歸的賓客套房。此外，Riviera 專屬的社交休息區，也規劃了跟私人俱樂部相同的各類設施。',
    },
    content2: {
      title: (
        <>
          戶外生活
          <br />
          日月相隨
        </>
      ),
      body: '社區中專屬的戶外露台，除了有綠意盎然的植物造景之外，也設置結合火爐桌的休息區。這裡典雅舒適，是您在享用美食或燒烤的好去處，此外也擁有充足空間，非常適合舉辦各類聚會活動。',
    },
    levels: {
      title: '溫馨天地 登峰造極',
      button1: '1 樓',
      button2: '7 樓',
    },
  },
  neighbourhood: {
    hero: {
      title: '動靜皆宜 多彩多姿',
      fullStrapline:
        '您能想到的一切都會生活體驗，本拿比樣樣皆有。從多元的美食餐廳、酒館、特殊商鋪、俱樂部、自然公園、兒童遊戲區，購物特區、舞蹈教室、咖啡廳以至於雞尾酒小酒館，都僅在咫尺之遙。',
    },
    content1: {
      title: (
        <>
          在鐵道鎮
          <br />
          盡享生活
        </>
      ),
      text: '鐵道鎮的 Metropolis 讓您不用進城，也能享受市中心的所有便利。不論是星期幾，您與一家大小只需散步幾分鐘，就能抵達這個大型精品商城，愜意地享受超過 300 間商舖提供的各種服務。',
    },
    content2: {
      title: (
        <>
          便捷出行
          <br />
          自由愜意
        </>
      ),
      text: 'Riviera 坐享公交網絡帶來的無比便捷，有兩個捷運站就在 5 分鐘不到的路程之內，不論您想前溫哥華市中心、溫哥華國際機場、或是附近的西門菲莎大學與卑詩省理工學院，皆是如此簡單便捷！',
    },
    select: '請選擇',
    map: [
      {
        title: '美食餐廳',
        list: [
          {
            name: 'Trattoria 義大利餐廳',
            top: '19.74%',
            left: '46.19%',
          },
          {
            name: 'Earls 餐廳',
            top: '30.99%',
            left: '48.77%',
          },
          {
            name: 'Cactus Club 西式餐廳',
            top: '37.82%',
            left: '47.42%',
          },
          {
            name: 'Chipotle 墨西哥速食餐廳',
            top: '47.97%',
            left: '55.89%',
          },
          {
            name: 'Mon Paris 法式甜點烘培坊',
            top: '44.09%',
            left: '40.04%',
          },
          {
            name: 'Sushi Garden 日本料理',
            top: '24.72%',
            left: '52.70%',
          },
          {
            name: 'Marutama 拉麵屋',
            top: '51.84%',
            left: '81.69%',
          },
          {
            name: "Nando's 烤雞餐廳",
            top: '45.75%',
            left: '64.61%',
          },
          {
            name: '星巴克',
            top: '33.39%',
            left: '64.98%',
          },
          {
            name: 'La Forêt 咖啡廳',
            top: '69.00%',
            left: '64.12%',
          },
          {
            name: '瘋味咖啡廳',
            top: '55.53%',
            left: '50.36%',
          },
          {
            name: '珍煮丹',
            top: '11.99%',
            left: '2.45%',
          },
          {
            name: '沸點台式火鍋餐廳',
            top: '56.27%',
            left: '87.46%',
          },
          {
            name: 'ZUBU 拉麵',
            top: '52.58%',
            left: '56.26%',
          },
          {
            name: 'Bella Gelateria 冰淇淋咖啡廳',
            top: '44.83%',
            left: '58.35%',
          },
          {
            name: '豆撈坊',
            top: '35.05%',
            left: '45.08%',
          },
        ],
      },
      {
        title: '休閒去處',
        list: [
          {
            name: 'Bonsor 社區中心',
            top: '55.53%',
            left: '62.89%',
          },
          {
            name: '鐵道鎮 Cineplex 電影院',
            top: '35.97%',
            left: '60.44%',
          },
          {
            name: '中央公園',
            top: '39.66%',
            left: '15.23%',
          },
          {
            name: '中央公園網球場',
            top: '32.28%',
            left: '22.97%',
          },
          {
            name: '中央公園小型高爾夫球場',
            top: '61.99%',
            left: '14.74%',
          },
          {
            name: 'Bonsor 公園',
            top: '60.88%',
            left: '65.35%',
          },
          {
            name: 'Kinnee 公園',
            top: '49.44%',
            left: '27.88%',
          },
          {
            name: 'Maywood 公園',
            top: '58.30%',
            left: '34.27%',
          },
          {
            name: 'GoodLife 健身中心',
            top: '20.84%',
            left: '47.54%',
          },
          {
            name: 'Fitness World 健身中心',
            top: '57.38%',
            left: '89.06%',
          },
          {
            name: 'Anytime 健身中心',
            top: '84.68%',
            left: '81.69%',
          },
          {
            name: 'David Gray 寵物公園',
            top: '84.68%',
            left: '35.50%',
          },
        ],
      },
      {
        title: '購物與各類服務',
        list: [
          {
            name: '鐵道鎮商場',
            top: '37.82%',
            left: '54.66%',
          },
          {
            name: '麗晶廣場',
            top: '26.56%',
            left: '44.34%',
          },
          {
            name: "Hudson's Bay 百貨公司",
            top: '43.91%',
            left: '66.58%',
          },
          {
            name: '卑詩汽車保險公司',
            top: '45.94%',
            left: '62.89%',
          },
          {
            name: 'Pharmasave 藥房',
            top: '43.91%',
            left: '38.94%',
          },
          {
            name: 'PriceSmart Foods 超市',
            top: '29.33%',
            left: '50.73%',
          },
          {
            name: 'RBC 皇家銀行',
            top: '19.37%',
            left: '37.71%',
          },
          {
            name: 'Real Canadian Superstore 超市',
            top: '33.39%',
            left: '52.82%',
          },
          {
            name: 'Walmart 超市',
            top: '46.67%',
            left: '53.80%',
          },
          {
            name: 'Save-On-Foods 超市',
            top: '14.94%',
            left: '44.34%',
          },
          {
            name: 'Rexall 藥妝店',
            top: '40.22%',
            left: '45.08%',
          },
          {
            name: '豐業銀行',
            top: '13.09%',
            left: '36.48%',
          },
          {
            name: 'Shoppers Drug Mart 藥妝店',
            top: '32.65%',
            left: '64.12%',
          },
          {
            name: '大統華超市',
            top: '44.46%',
            left: '50.98%',
          },
          {
            name: '多倫多道明銀行',
            top: '27.30%',
            left: '48.28%',
          },
          {
            name: '匯豐銀行',
            top: '23.06%',
            left: '45.82%',
          },
          {
            name: 'Vancity 信用合作社',
            top: '37.82%',
            left: '71.74%',
          },
          {
            name: '本拿比希爾頓酒店',
            top: '30.25%',
            left: '45.82%',
          },
          {
            name: 'Best Buy 電器用品專賣店',
            top: '35.23%',
            left: '45.08%',
          },
          {
            name: '卑詩酒舖',
            top: '13.09%',
            left: '41.40%',
          },
          {
            name: 'London Drugs 藥妝店',
            top: '40.77%',
            left: '69.28%',
          },
          {
            name: '本拿比公共圖書館',
            top: '34.50%',
            left: '40.66%',
          },
          {
            name: 'WeWork 共享工作空間',
            top: '33.39%',
            left: '48.28%',
          },
        ],
      },
      {
        title: '學校',
        list: [
          {
            name: 'Marlborough 小學',
            top: '28.41%',
            left: '72.60%',
          },
          {
            name: 'Maywood 社區學校',
            top: '61.25%',
            left: '54.66%',
          },
          {
            name: 'Burnaby South 中學',
            top: '91.51%',
            left: '95.82%',
          },
          {
            name: 'South Slope 小學',
            top: '84.68%',
            left: '42.50%',
          },
          {
            name: 'Wonderworld 蒙特梭利學校',
            top: '45.20%',
            left: '41.03%',
          },
          {
            name: 'CEFA 早期教育',
            top: '4.98%',
            left: '19.16%',
          },
          {
            name: 'Suncrest 小學',
            top: '91.51%',
            left: '19.16%',
          },
          {
            name: 'Nelson 小學',
            top: '91.51%',
            left: '63.51%',
          },
        ],
      },
    ],
  },
  floorplans: {
    title: <>A 體現中世紀典雅風格的 1 房加書房至 3 房系列精品住宅</>,
    plan: '戶型',
    type: '種類',
    size: '大約平方呎',
    download: '下載戶型圖',
    disclaimer:
      'In a continuing effort to meet the challenge of product improvements, we reserve the right to modify or change dimensions, sizes, specifications, layouts, plan orientations and materials without notice. All patio, terrace and balcony sizes may vary. Windows, columns, privacy screens, demising walls, spandrel and guardrail details may vary depending upon the plan and floor level. The quality of homes at Riviera are built by LM Riviera Homes Limited Partnership. E.&O.E.',
    views: {
      title: (
        <>
          理想社區的 <br />
          360 度 <br />
          壯麗全景
        </>
      ),
      drag: '拖動以平移景觀',
    },
  },
  privacy: {
    title: '隱私政策',
    body: (
      <>
        <p>
          This Privacy Policy has been prepared by Ledingham McAllister for
          their required site (herein known as “Riviera”, “we”, “us”, “our”) and
          sets out the way Ledingham McAllister collects, discloses, use, and
          otherwise manages personal information.
          <br />
          <br />
          The Privacy Policy also describes the privacy practices on the
          Ledingham McAllister required site website (the “website”, the “site”,
          or the “websites”) and through other interactions with consumers.
        </p>

        <h3>Collection and Use of Personal Information</h3>
        <p>
          <span>Guest Registry:</span> When you visit one of our sales centres
          and complete a guest registry form, we collect contact information
          such as your first and last name, your mailing address, your email
          address, how you heard about us and your personal phone numbers. You
          may also choose to provide optional additional information such as
          your current housing situation, your reasons for moving, where you
          currently reside, your desired housing characteristics, family status,
          age group, number of adults and children and your annual household
          income.
          <br />
          <br />
          We only use this information that you have provided on the Guest
          Registry to form a better understanding of your needs, aid you in
          finding a suitable home and to send you tailored communications about
          our developments, products and services.
          <br />
          <br />
          We also use this information on an aggregator basis to help us better
          understand our customers and to improve our products and service
          offerings.
          <br />
          <br />
          <span>Buying a Home:</span> When you complete a CPS (Contract of
          Purchase and Sale) for a residential property through Ledingham
          McAllister at any of our developments, we will collect the information
          you provided on the Agreement including the first and name and contact
          information (such as telephone numbers and mailing address) for the
          purchasers, including the property description, and purchase amount.
          We utilize this information for the purposes of facilitating the
          residential property transaction.
          <br />
          <br />
          Additionally, we will collect pertinent information to verify the
          identity of each purchaser (such as a valid government-issued piece of
          identification) as well as a personal cheque for deposit purposes and
          as required by law, REDMA and Fintrac rules, regulations and policies.
          <br />
          <br />
          <span>Warranty Registration and Requests:</span> We collect certain
          pieces of personal information, including your name, email address,
          mailing address, telephone numbers and property closing date when you
          register for a new home warranty plan in your respective and
          applicable province or when you make a warranty customer care request.
          This information will be used to complete the warranty registration
          and to fulfill your warranty request.
          <br />
          <br />
          <span>Website Registration:</span> In order to obtain access to
          information regarding products and services on any password-protected
          areas of our websites, we may request certain personal information
          such as your name, telephone number, mailing address, email address
          and the corresponding password that you have selected. We use this
          information to provide and administer your online account or service
          your request.
          <br />
          <br />
          <span>Marketing Communications:</span> When you complete and submit a
          guest registration form, or otherwise sign-up to receive information
          regarding our developments or related products and services, we
          collect your contact information such as your name, phone numbers,
          mailing addresses and email address. We will use this information to
          send you communications based on your expressed interests by mail,
          email or telephone. You may opt-out of receiving all future marketing
          and promotional communications at any time by clicking on the
          unsubscribe link included in our email communications, or by
          contacting us with the credentials noted at the bottom of this Privacy
          Policy.
          <br />
          <br />
          <span>Customer Service:</span> Any time that you contact us with a
          comment, complaint or a question, you may be asked for information to
          help us identify yourself (such as your name, address, and your
          telephone number) along with additional information we may need to
          help us promptly answer your question or respond to your comment or
          complaint (e.g: your lot number, warranty enrolment number,
          development, community, etc.) We may retain this information to assist
          you in the future and to improve our customer service and product and
          service offerings. We may additionally use personal information to
          establish and manage our relationship with you and provide quality
          customer service.
        </p>
        <h3>Disclosure and Sharing of Your Personal Information</h3>
        <p>
          We will not under any circumstances disclose, rent, trade, sell or
          otherwise transfer your personal information without your consent,
          except as otherwise outlined herein.
          <br />
          <br />
          <span>Service Providers:</span> Your personal information may be
          transferred (or otherwise made available) to our designated third
          parties or affiliates who provide services on our behalf. As an
          example, we may utilize a service provider for the maintenance of our
          website, including hosting an information form, providing additional
          services related to our site, sending electronic mail or other
          functions related to our business or services provided.
          <br />
          <br />
          Our service providers are given only the information they need to
          perform designated functions, and are not authorized under any
          circumstances to disclose personal information for their own marketing
          purposes.
          <br />
          <br />
          Your personal information may be maintained and processed by us, our
          affiliations and other third-party service providers in Canada, the US
          or other foreign jurisdictions. In the event that personal information
          is transferred to other foreign jurisdictions, it will be subject to
          the laws of that country and may be disclosed to or accessed by their
          respective courts of law (or related parties), local law enforcement
          and governmental authorities in accordance to their laws.
          <br />
          <br />
          <span>Legal/Compliance:</span> Ledingham McAllister, its affiliates,
          sister companies and its Canadian or foreign service providers may
          provide your personal information in response to a search warrant or
          other legally valid order or inquiry to another organization for the
          purposes of investigating any breaches of an agreement, violation of a
          law or detecting, preventing or suppressing fraud, or as otherwise
          permitted or required by applicable Canadian or other foreign laws.
          Your personal information may also be disclosed where necessary for
          the establishment, exercise or defense of legal claims and to prevent
          or investigate loss or harm to persons or property.
          <br />
          <br />
          <span>Sale of Business:</span> Personal information may be provided to
          third parties in connection with a prospective or completed business
          transaction, including a sale or merger (including transfers made as
          part of bankruptcy proceedings or insolvency) involving all or a part
          of Ledingham McAllister or as a part of a corporate reorganization or
          stock sale or additional or other changes in corporate control.
        </p>
        <h3>
          Internet-Based Advertising, Information About our Website and Cookies
        </h3>
        <h4>OVERVIEW</h4>
        <p>
          We and/or our service providers collect IP (Internet protocol)
          addresses from all visitors to the website and other related
          information such as browser type, operating system, page requests and
          an average time spent visiting our website.
          <br />
          <br />
          This information is only used to enable us to understand our site’s
          activity and to improve and monitor the website.
        </p>
        <h4>TRACKING TAGS, WEB BEACONS AND COOKIES</h4>
        <p>
          Our site uses a technology called ’web beacons’ and ‘tracking tags’.
          These systems allow us to understand which pages you frequent on our
          site. Tracer tags like these are utilized to help us learn how we can
          tailor and optimize our website for yourself and other site visitors.
          <br />
          <br />
          Our website utilizes a technology known as ‘cookies.’ Cookies are
          essentially pieces of data that sit on your hard drive so we can
          recognize you again when you return to our site.
          <br />
          <br />
          We utilize cookies on the sections of our site in which you are
          prompted to log in or have boxes which are customizable. In the event
          that you have registered on our site, these cookies will allow us to
          identify who you are and which browser you are using and will allow us
          to recognize that you have previously visited the site and it is may
          be necessary to access your account information (which is
          automatically stored on our computers) in an effort to personalize
          services and deliver products.
          <br />
          <br />
          Cookies provide us and our service providers with information
          pertaining to your utilization of our site (e.g: which sections of the
          site you have frequented) that will then be used to personalize our
          site in accordance with your designated interests and preferences.
          <br />
          <br />
          Our site is not designed to respond to ‘do not track’ signals that are
          received from browsers.
        </p>
        <h4>INTERNET-BASED ADVERTISING</h4>
        <p>
          You may find our advertisements on other websites through the usage of
          our third parties, such as Facebook. These companies may additionally
          use web beacons, tracer tags, cookies to report understand certain
          information surrounding your visits to our site and other sites you
          visit and/or interact to ads, in order to measure the effectiveness of
          our marketing campaigns and to deliver the appropriate ads which are
          relevant to you on and off our website.
          <br />
          <br />
          If you would like to learn additional information about the collection
          and use of information by third parties for behavioural purpose.
          Additionally, if you would like to opt-out of certain third-party
          cookies or selection what online advertisements you choose to receive,
          you may do so by visiting the Digital Advertising Alliance of Canada
          website.
          <br />
          <br />
          If you would like opt-out of internet-based ads from Google, you may
          do so here.
        </p>
        <h4>ANALYTICS</h4>
        <p>
          We may utilize services from a third party such as Google Analytics to
          aid us in obtaining and analyzing information about the sections of
          our site which you frequent. These third parties may use technologies
          as noted above including: web beacons, web server logs and cookies.
          This information will be used to improve and evaluate your customer
          experience and the simplicity and convenience of our website, in
          addition to aiding us in understanding the specific ways in which you
          use our site based on your website visits. If you would like further
          information regarding how Google uses data when on a patterns’ sites
          or applications, you may do so here.
        </p>
        <h4>GOOGLE ANALYTICS</h4>
        <p>
          Google Analytics is a web analytics service offered by Google that
          tracks and reports website traffic. Google uses the data collected to
          track and monitor the use of our service. This data is shared with
          other Google services. Google may use the collected data to
          contextualize and personalize the ads of its own advertising network.
          <br />
          <br />
          You can opt-out of having made your activity on the service available
          to Google Analytics by installing the Google Analytics opt-out browser
          add-on. The add-on prevents the Google Analytics JavaScript (ga.js,
          analytics.js, and dc.js) from sharing information with Google
          Analytics about visits activity.  <br />
          <br />
          For more information on the privacy practices of Google, please visit
          the Google Privacy & Terms web
          page: https://policies.google.com/privacy?hl=en
        </p>
        <h4>FACEBOOK ADVERTISEMENTS</h4>
        <p>
          Facebook offers advertising that targets users based on designed
          audiences and specific objectives. Facebook collects this data based
          on provided and approved user parameters.
          <br />
          <br />
          Alternatively, ads may target you based off of data from your browser
          through the utilization of algorithms. Facebook may use the collected
          data to contextualize and personalize the ads of its own advertising
          network.
          <br />
          <br />
          You can opt-out of ads by going to your Facebook profile, selecting
          settings, ad, and then making modification in the Ad settings (Ads
          based on data from partners) by selecting ‘Not Allowed.’
        </p>
        <h4>SOCIAL MEDIA</h4>
        <p>
          This site may provide you with the opportunity to engage with our
          content on or through third-party social networking sites,
          applications or plug-ins. When you engage with our content on or
          through third-party operated social networking websites, applications
          or plug-ins, you may allow us to have access to certain information
          that is associated with your social media accounts (Username, email,
          name) to deliver the content or as a part of the operation of the
          website, application or plug-in. When you provide information from
          your social media account, we may utilize this information to
          personalize your experience on our site and on the third-party social
          networking websites, applications or plug-ins and to provide you with
          other services or products you may request.
        </p>
        <h4>THIRD PARTY LINKS</h4>
        <p>
          Our site may contain links to other sites that are not owned or
          operated by us, this includes social media websites. The links to our
          website may be featured on third party websites on which we advertise
          on. Except as you provided herein, we will not under any circumstances
          provide any of your personal information to these third parties
          without your explicit consent. These links are provided to third party
          websites as a convenience to you as the site user. Links are not
          intended to act as an endorsement of or a referral to the linked
          sites. These linked sites have independent and separate privacy
          statements, terms of use and notices which we advise you to read very
          carefully. We do not have any control over these websites, and as
          such, have no liability or responsible for the manner in which these
          organizations that operate these linked websites may use, disclose,
          collect, secure or otherwise treat your personal information.
        </p>
        <h3>Retention and Safeguards</h3>
        <p>
          We have applied reasonable administrative, technical and physical
          measures in an effort to protect the personal information in our
          custody and control against loss, theft and unauthorized access
          including the usage, modification and disclosure of information. We
          restrict access to your personal information on a need to know basis
          to employees and authorized service providers who need access in order
          to fulfill their job requirements.
          <br />
          <br />
          Your online access to your personal information may be protected with
          a password that you have selected. We strongly discourage you from
          disclosing or sharing your password with anyone. We will never prompt
          you for your password via any unsolicited communication (such as phone
          calls, email, phone calls, or social media messaging systems).
          <br />
          <br />
          Our personal information retention processes are meant to retain
          personal information of our customers for no longer than necessary for
          the purposes stated above or to otherwise adhere to legal parameters.
        </p>
        <h4>ACESS TO YOUR PERSONAL INFORMATION</h4>
        <p>
          You retain the right to access, update and correct inaccuracies in
          your personal information that we have in our custody and control.
          This is subject to personal exceptions as prescribed by the law.
          <br />
          <br />
          You may request access to update, modify and correct inaccuracies in
          personal information that have in our possession or control by
          emailing, writing us, or calling us through the contact information as
          noted below.
          <br />
          <br />
          We may in turn request certain information for verification purposes
          in order to properly identify you as the appropriate person seeking
          access to their personal information records.
        </p>
        <h4>CHANGES TO THE PRIVACY POLICY</h4>
        <p>
          This privacy policy may be updated periodically to reflect changes to
          our personal information practices in accordance with the law. We will
          post the updated Privacy Policy on our website; your personal
          information will always be treated in accordance to what is noted
          within the Privacy Policy in place at the time your personal
          information was collected, unless you otherwise consent.
        </p>
        <h4>CONTACT US</h4>
        <p>
          If you have any questions about this Privacy Policy, please contact
          us:
          <br />
          <br />
          By email:{' '}
          <a href="mailto:admin@rivierabyledmac.com">
            admin@rivierabyledmac.com
          </a>
          <br />
          By visiting this page on our website:{' '}
          <a
            href="https://rivierabyledmac.com/"
            target="_blank"
            rel="noopener noreferrer"
          >
            https://rivierabyledmac.com/
          </a>
          <br />
          By phone number: <a href="tel:778-658-0288">778-658-0288</a>
          <br />
        </p>
      </>
    ),
  },
  notFound: {
    title: (
      <>
        抱歉！ <br />
        找不到頁面
      </>
    ),
    strapline: '404 找不到頁面',
    body: (
      <>
        發生錯誤 <br />
        您想前往的頁面並不存在
      </>
    ),
    button: '回到首頁',
  },
  thankYou: {
    title: 'Thank You',
    body: (
      <>
        謝謝您登記由 Ledingham McAllister 傾力打造的 Riviera
        <br />
        我們已經收到您的訊息，將安排專人盡快與您聯繫
      </>
    ),
    button: '回到首頁',
  },
};
