import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import vw from '../styles/utils';
import Button from './Button';
import useIntl from './useIntl';

const CloseIcon = (props) => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={props.className}
      onClick={props.onClick}
    >
      <rect
        width="25.3127"
        height="2.53131"
        transform="matrix(0.70374 0.710458 -0.70374 0.710458 2.18643 0.217773)"
        fill="#B7A165"
      />
      <rect
        width="25.3127"
        height="2.53131"
        transform="matrix(0.703727 -0.71047 0.703727 0.71047 0 17.9846)"
        fill="#B7A165"
      />
    </svg>
  );
};

const Popup = () => {
  const [open, setOpen] = useState(false);
  const { t, getLocaleURL } = useIntl();
  useEffect(() => {
    setTimeout(() => {
      setOpen(true);
    }, 3000);
  }, []);

  const handleClick = () => {
    setOpen(false);
  };

  return (
    <Root open={open} onClick={() => setOpen(false)}>
      <Container onClick={(e) => e.stopPropagation()}>
        <PopupDesktop
          src={require('src/assets/images/popup/riviera-desktop-popup@2x.webp')}
        />
        <PopupMobile
          src={require('src/assets/images/popup/riviera-mobile-popup.webp')}
        />
        <Close onClick={() => setOpen(false)} />
        <StyledButton
          // to={`${getLocaleURL()}/register`}
          href="https://app.acuityscheduling.com/schedule.php?owner=20816987&location=4700%20Imperial%20Street%2C%20Burnaby%2C%20BC"
          text={t.bookAppt}
          gold
          className="button uppercase"
          onClick={handleClick}
        />
      </Container>
    </Root>
  );
};

const Root = styled.div`
  background: rgba(0, 38, 58, 0.7);
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  z-index: 999;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;

  opacity: ${({ open }) => (open ? 1 : 0)};
  pointer-events: ${({ open }) => (open ? 'all' : 'none')};
  transition: all 0.5s;
`;

const Container = styled.div`
  position: relative;
  cursor: default;
  ${vw('width', 280, 340, 900)}
  ${vw('height', 560, 680, 550)}
  display: flex;
  justify-content: center;
`;

const PopupDesktop = styled.img`
  ${vw('display', 'none', 'none', 'block')}
  ${vw('width', 280, 340, 900)}
`;

const PopupMobile = styled.img`
  ${vw('display', 'block', 'block', 'none')}
  ${vw('width', 305, 340, 900)}
`;

const Close = styled(CloseIcon)`
  position: absolute;
  cursor: pointer;
  ${vw('top', 20, 25, 25)}
  ${vw('right', 10, 25, 25)}
  ${vw('width', 20, 24, 20)}
  ${vw('height', 20, 24, 20)}
`;

const StyledButton = styled(Button)`
  position: absolute;
  ${vw('bottom', 165, 205, 124)}
  display: block;
  z-index: 2;
`;

export default Popup;
